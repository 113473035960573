import React from "react"
import Layout from "./layout"
import { Link , graphql } from "gatsby"

import * as list from "../components/css/blogList.module.scss"

import ogpImage from "../images/wonderwall-ogp.png"

export const query = graphql`
  query($slug: String!, $limit: Int!, $skip: Int!) {
    allWpPost (filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}, limit: $limit skip: $skip) {
      edges {
        node {
          id
          title
          date(fromNow: false, locale: "jp", formatString: "yyyy-MM-dd'T'HH:mm:ss")
          excerpt
          slug
          featuredImage {
            node {
              localFile {
                url
                childImageSharp {
                  fluid(maxWidth: 1024)  {
                    ...GatsbyImageSharpFluid
                  }
                  gatsbyImageData (
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    allWpCategory (filter: {slug: {eq: $slug}}) {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`
const blogCategoryList = ({ data, pageContext }) => {
  const cate = pageContext.slug
  const cateName = data.allWpCategory.edges[0].node.name;
  const paginatedPagesCount = Math.ceil(
    data.allWpPost.edges.length / pageContext.limit
  )
  const currentPage = pageContext.currentPage
  const isFirst = currentPage === 1
  const isLast = currentPage === paginatedPagesCount
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout {...pageContext}>
      <div id="blogList" className={`${list.wrap} cate-${cate}`} data-cate={`${cate}`} >

        <section class="ww-section-articles">
            <div class="articles-header-wrapper">
              <div class="articles-header-position">
                <h2>ワンダウォールの記事<span class="label">{cateName}</span></h2>
              </div>
            </div>

            <ul className={`${list.articlesPosition} articles-position`} itemScope="" itemType="http://schema.org/BreadcrumbList">

              {data.allWpPost.edges.map((edge,i) => {
                var image = (edge.node.featuredImage) ? edge.node.featuredImage.source_url : ogpImage
                return (
                  <li className={`${list.articlesItem} articles-item`} itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem" key={i}>
                  <Link to={`/articles/${edge.node.slug}`} itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                    <div className={`articles-item-wrapper`} >
                      <h4 className={`article-header`} >{cateName}</h4>
                      <h3 className={`article-copy`} itemProp="name">{edge.node.title}</h3>
                    </div>
                    <figure class="featuredImageWrapper"></figure>
                    <figure class="featuredImage" style={{ backgroundImage: `url(${image})`}}></figure>
                  </Link>
                  </li>
                )
              })}
            </ul>
            <div class="pagenation">
            {!isFirst && (
              <Link to={`/articles/${cate}/${prevPage}`} rel="prev">
                ← 前のページへ
              </Link>
            )}
            {!isLast && (
                <Link to={`/articles/${cate}/${nextPage}`} rel="next">
                  次のページへ →
                </Link>
            )}
            </div>
        </section>

      </div>
    </Layout>
  )
}

export default blogCategoryList